import { render, staticRenderFns } from "./AztecGems.vue?vue&type=template&id=68471730&scoped=true"
import script from "./AztecGems.vue?vue&type=script&lang=js"
export * from "./AztecGems.vue?vue&type=script&lang=js"
import style0 from "./AztecGems.vue?vue&type=style&index=0&id=68471730&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68471730",
  null
  
)

export default component.exports